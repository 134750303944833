// Here you can add other styles
body {
  background-color: white;
}

.btn-primary {
  border-color: #69410d;
  background-color: #855722;
  color: #fff;
}

.btn-primary:hover {
  border-color: #69410d;
  background-color: #855722;
  color: #fff;
}

a {
  color: #b7a22c;
}

a:hover {
  color: #b7a22c;
  text-decoration: underline;
}

.bottom-0 {
  bottom: 0 !important;
}

.end-0 {
  right: 0 !important;
}

.sidebar .nav-link.active .nav-icon {
  color: #b7a22c;
}

.sidebar .nav-link:hover {
  background: #b7a22c;
  color: #fff;

  /* background: #0e750e; */
}

.sidebar .nav-link:hover .nav-icon {
  color: #fff;
}

nav ol.breadcrumb {
  margin-bottom: 0;
}

.bg-primary {
  background-color: #b7a22c !important;
}

.card.bg-primary {
  border-color: #b7a22c;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  border-color: #95b72c;
  background-color: #95b72c;
  color: #fff;
}

textarea.focus-0:focus {
  outline: none !important;
  box-shadow: none !important;
}

input.focus-0:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

input.focus-0-border:focus {
  outline: none !important;
  box-shadow: none !important;
}

.justyfy-content-flex-end {
  justify-content: flex-end;
}

.progress.medium {
  height: 0.5rem;
}

.overlay {
  position: fixed;
  z-index: 1021;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba($color: #000, $alpha: 0.4);
}

.overOverlay {
  z-index: 1022;
}

li.task-item:hover {
  box-shadow: 0 0 2px 0 rgba($color: #000, $alpha: 0.38);
}

li.task-item .actions-cont {
  visibility: hidden;
}

li.task-item:hover .actions-cont {
  visibility: visible;
}

a.backlog-form-action-link {
  color: #42526e;
}

a.backlog-form-action-link:hover {
  background-color: #ecedf0;
  text-decoration: none;
}

i.task-note {
  color: seagreen;
}

i.task-message {
  color: lightseagreen;
}

i.task-activity {
  color: blue;
}

a.task-list-link:hover {
  background-color: #efefef;
  text-decoration: none;
}

.close-button {
  position: absolute;
  z-index: 999;
  top: 20px;
  right: 5px;
}

.close-button.top-10 {
  top: 10px;
}

a.close-button:hover {
  text-decoration: none;
}

.task-name-action {
  cursor: pointer;
}

.task-name-action:hover {
  background-color: #ecedf0;
}

.col-0 {
  position: relative;
  -webkit-flex: 0 0 0;
  flex: 0 0 0;
  max-width: 0;
  height: 0;
}

.filter-gravatar-cont {
  min-width: 17px;
  max-width: 43px;
}

.filter-gravatar {
  width: 45px;
  min-width: 45px;
}

.list-gravatar {
  width: 26px;
  min-width: 26px;
  font-size: 9px;
}

.opacity-0 {
  opacity: 0 !important;
}

.pointer {
  cursor: pointer !important;
}

.border-width-2 {
  border-width: 2px !important;
}

table thead th {
  border-bottom: 2px solid #c8ced3;
  color: rgba($color: #000, $alpha: 0.55);
  font-size: 12px;
  font-weight: normal;
  vertical-align: bottom;
}

.badge.circle {
  border-radius: 100px;
}

.table th,
.table td {
  padding: 0.5rem;
}

.card-header .btn {
  padding: 0.2rem 0.75rem;
}

.sidebarCollapsed {
  position: absolute;
  top: 87px;
  left: -42px;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.backlog-column {
  // max-height: 55vh;
  overflow-y: auto;
}

.sticky-bottom {
  position: -webkit-sticky;
  position: sticky;
  z-index: 10;
  bottom: 0;
  transform: translate(0, 0);
  transition: transform 0.3s cubic-bezier(0.86, 0, 0.07, 1) 0.1s;
}

.pop-list-group {
  z-index: 12;
}

.comment-bubble::before {
  content: '';
  position: absolute;
  top: 0;
  left: -13px;
  border: solid;
  border-width: 0 12px 12px 0;
  border-radius: 27px 2px 0 24px;
  border-right-color: #c8ced361;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

.new-comment {
  animation: Backround 2s normal forwards ease-in-out;
  background-color: rgb(242, 245, 70);
}

.public-DraftStyleDefault-block {
  margin: 0 !important;
}

.rdw-editor-main {
  min-height: 100px;
  padding: 0.2em;
}

@keyframes Backround {
  0% {
    background-color: rgba(242, 245, 70, 0.75);
  }

  50% {
    background-color: rgba(242, 245, 70, 0.25);
  }

  100% {
    background-color: transparent;
  }
}

@media (max-width: 575.98px) {
  .main .container-fluid {
    padding: 0 15px;
  }
}

.file-card-body {
  min-height: 9rem;
  max-height: 9rem;
  overflow: hidden;
}

.file-card-body .file-actions {
  display: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(47, 53, 58, 0.4);
}

.file-card-body:hover .file-actions {
  display: inline-flex;
}

.switch-3d.switch-sm {
  width: 40px;
}

.switch-3d.switch-sm .switch-input:checked ~ .switch-slider::before {
  -webkit-transform: translateX(20px);
  transform: translateX(20px);
}

.switch-3d .switch-slider::before {
  box-shadow: 0 2px 10px rgba($color: #000, $alpha: 0.9);
}
