// Variable overrides
.font-title {
  font-size: 1.09375rem;
}
.font-primary {
  color: #b7a22c;
}
.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}
.font-11 {
  font-size: 11px;
}
